import { httpApi } from '@app/api/http.api';
import { UserModel } from '@app/models/UserModel';

export interface AuthData {
  email: string;
  password: string;
}

export interface SignUpRequest {
  tipoUsuario: string;
  correo: string;
  password: string;
}

export interface ResetPasswordRequest {
  email: string;
}

export interface SecurityCodePayload {
  code: string;
}

export interface NewPasswordData {
  newPassword: string;
}

export interface LoginRequest {
  email: string;
  password: string;
}

export interface AuthRequest {
  username: string;
  password: string;
  grant_type: string;
  client_id: string | undefined;
  client_secret: string | undefined;
}

export interface LoginResponse {
  token: string;
  user: UserModel;
}

export interface AuthResponse {
  data: {
    user: UserModel;
    access_token: string;
  };
}
const axiosConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const login = (loginPayload: AuthRequest): Promise<AuthResponse> =>
  httpApi.post<AuthResponse>('/login', { ...loginPayload }, axiosConfig).then(({ data }) => data);

export const signUp = (signUpData: SignUpRequest): Promise<undefined> =>
  httpApi.post<undefined>('/registro', { ...signUpData }).then(({ data }) => data);

export const resetPassword = (resetPasswordPayload: ResetPasswordRequest): Promise<undefined> =>
  httpApi.post<undefined>('forgotPassword', { ...resetPasswordPayload }).then(({ data }) => data);

export const verifySecurityCode = (securityCodePayload: SecurityCodePayload): Promise<undefined> =>
  httpApi.post<undefined>('/verificar', { ...securityCodePayload }).then(({ data }) => data);

export const setNewPassword = (newPasswordData: NewPasswordData): Promise<undefined> =>
  httpApi.post<undefined>('setNewPassword', { ...newPasswordData }).then(({ data }) => data);

export const getUserFromCode = (code: string): Promise<any> =>
  httpApi.get<any>(`/getUserFromCode/${code}`).then(({ data }) => data);

export const actualizarDatosUsuario = (data: any): Promise<any> =>
  httpApi.post<any>('actualizarDatosUsuario', { ...data }).then(({ data }) => data);

export const getUserFromID = (id_usuario: string): Promise<any> =>
  httpApi.get<any>(`/getUserFromID/${id_usuario}`).then(({ data }) => data);
